<template>
  <div>
    <div class="title" v-if="flag == 1">
      <navi-gation />

      <!-- 查询 -->
      <el-row :gutter="4" style="padding: 10px 0 0 10px">
        <el-col :span="3">
          <el-input
            size="small"
            :placeholder="$t('AccessConfiguration.GatewayConfig.Placeholder')"
            v-model="input"
            @keyup.enter.native="onSbumit"
            @input="(e) => (input = validForbid(e))"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button
            type="primary"
            style="height: 32px"
            size="small"
            @click="onSbumit"
            >{{ $t("public.Inquire") }}</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="3" style="padding: 10px 0 0 10px">
        <el-button-group>
          <el-button type="primary" size="small" @click="flag = 2">{{
            $t("AccessConfiguration.GatewayConfig.NewGatewayBtn")
          }}</el-button>
          <el-button
            style="margin-left: 1px"
            @click="delMuch(delarr)"
            :type="delType"
            size="small"
            class="delBtn"
            :disabled="delFlag"
            >{{ $t("public.Delete") }}</el-button
          >
          <!-- <el-button style="margin-left: 1px" type="primary" size="small" class="delBtn" v-else>删除</el-button> -->
        </el-button-group>
      </el-row>
      <el-row
        :gutter="16"
        class="mt16"
        style="margin-right: 10px; margin-left: 10px"
      >
        <el-table
          size="mini"
          :row-key="getRowKeys"
          stripe
          ref="multipleTable"
          :data="tableData"
          class="sort_table"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 10px"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          @selection-change="handleSelectionChange"
          :default-sort="{ prop: 'update_time', order: 'descending' }"
          @sort-change="sort"
        >
          <el-table-column
            :reserve-selection="true"
            header-align="center"
            align="center"
            type="selection"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="gwName"
            :show-overflow-tooltip="true"
            :label="$t('AccessConfiguration.GatewayConfig.GatewayName')"
            width="150"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.gwName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="gwAddress"
            type="textarea"
            :rows="4"
            :show-overflow-tooltip="true"
            :label="$t('AccessConfiguration.GatewayConfig.GatewayAddress')"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="gwPort"
            type="textarea"
            :rows="4"
            :show-overflow-tooltip="true"
            :label="$t('public.Port')"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="resourceNames"
            type="textarea"
            :rows="4"
            :show-overflow-tooltip="true"
            :label="$t('AccessConfiguration.GatewayConfig.Protected')"
            width="300"
          >
          </el-table-column>
          <el-table-column
            prop="update_time"
            :label="$t('AccessConfiguration.GatewayConfig.ModificationTime')"
            sortable="custom"
            :show-overflow-tooltip="true"
            width="180"
          >
          <template v-slot="{row}">
               {{row.updateTime}}   
           </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <img
                @click="nameKuang(row)"
                src="@/assets/icon_see.png"
                style="width: 15px; height: 15px; cursor: pointer"
                :title="$t('AccessConfiguration.GatewayConfig.GatewayAssocia')"
              />
              <img
                @click="edit(row)"
                src="../../assets/icon_write.png"
                alt=""
                style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                "
                :title="$t('public.Editor')"
              />
              <img
                v-show="
                  row.name !=
                    $t('AccessConfiguration.GatewayConfig.SocialChat') &&
                  row.name !=
                    $t('AccessConfiguration.GatewayConfig.OfficeBusiness')
                "
                @click="deletes(row)"
                src="../../assets/icon_del.png"
                alt=""
                style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                "
                :title="$t('public.Delete')"
              />
            </template>
          </el-table-column>
        </el-table>
        <div>
          <pagination
            :page="listData.currentPage"
            :limit="listData.pageSize"
            :total="listData.total"
            :pageSizes="[10, 20, 30, 40]"
            v-on:handleChildGetList="handleParentGetList"
          />
        </div>
      </el-row>
    </div>
    <!-- 新增 GatewayResource -->
    <GatewayResource
      v-if="flag == 2"
      :addArray="addArray"
      :addArrayId="addArrayId"
      @sunline="sunline"
    ></GatewayResource>
    <!-- 关联应用的弹框 -->
    <Eldialog
      :num="'520px'"
      :title="$t('AccessConfiguration.GatewayConfig.Association')"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
      style="overflow: auto"
    >
      <el-input
        v-model="ipt"
        @input="(e) => (ipt = validForbid(e))"
        @keyup.enter.native="updateData"
        size="small"
        style="width: 180px; margin-bottom: 10px"
        :placeholder="$t('AccessConfiguration.GatewayConfig.PlacesAss')"
      >
        <i
          @click="updateData"
          slot="suffix"
          class="el-input__icon el-icon-search"
          style="cursor: pointer"
        ></i>
      </el-input>
      <el-table
        size="mini"
        stripe
        ref="multipleTable"
        :data="tableDataNmae"
        class="sort_table"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
        :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="name"
          :label="$t('AccessConfiguration.GatewayConfig.Policyname')"
          width="150"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="osTypeId"
          :label="$t('public.OperatingSystem')"
        >
          <template v-slot="{ row }">
            <template v-if="row.osTypeId == 10"> iOS </template>
            <template v-if="row.osTypeId == 20"> Android </template>
            <template v-if="row.osTypeId == 30"> Windows </template>
            <template v-if="row.osTypeId == 40"> Linux </template>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="groupFullName"
          :label="$t('public.Section')"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="lastUpdateTime"
          :label="$t('public.ModificationTime')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
      </el-table>
      <p style="margin-top: 10px">
        {{ $t("AccessConfiguration.GatewayConfig.Common")
        }}<b>{{ this.dataTolCount }}</b
        >{{ $t("AccessConfiguration.GatewayConfig.Records") }}
      </p>
    </Eldialog>
    <!-- 提示消息框 -->
    <dialogInfo
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import Eldialog from "@/components/elDialog"; //详情弹框
import naviGation from "@/components/hearder/index"; //头部组件
import pagination from "@/components/pagination/page.vue"; //分页
import dialogInfo from "@/components/promptMessage/index"; //提示消息框
import GatewayResource from "./components/GatewayResource.vue"; //提示消息框
export default {
  components: {
    Eldialog,
    naviGation,
    pagination,
    dialogInfo,
    GatewayResource,
  },
  data() {
    let validcodeName = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      if (!reg.test(value)) {
        callback(new Error("不支持空格及特殊字符"));
      } else {
        callback();
      }
    };
    return {
      id: "",
      ipt: "", //点击名称里面的查询
      flag: 1, //显示隐藏切换
      // page: {
      //   // 分页
      //   total: 0, //总条数
      //   pageSize: 10, //每页条数,  默认10条
      //   orderRule: 'ASC',
      //   orderColume: 'UPDATE_TIME',
      //   currentPage: 1
      // },

      input: "", //关联应用弹框的查询
      delarr: [], //存放要删除的数据
      dataId: "",
      delType: "info", //删除按钮类型默认置灰
      delFlag: true, //删除按钮禁用默认
      addArray: null,
      ruleForm: {
        //新增参数
        name: "",
        memo: "",
      },
      listData: {
        // 列表参数
        total: 0,
        manage: "1",
        pageSize: 10,
        orderRule: "DESC",
        orderColume: "update_time",
        appCategory: "",
        currentPage: 1,
      },
      // nameData: {
      //   id: '',
      //   pageSize: 9999, //rowNumber
      //   orderRule: 'DESC',
      //   currentPage: 1, //pageNumber
      //   orderColume: 'UPDATE_TIME'
      // },

      tableData: [], // 数据列表
      titleInfo: "",
      titleInfo: "",
      addArrayId: "", //新增点击传过去组织树的id
      flagDisable: false, // 遮罩全选
      isCheckShow: false,
      dataTolCount: "", //点击列表的总数
      visibaelFlag: false, //提示消息弹框默认不显示

      ruleFormEdit: {
        //编辑参数
        name: "",
        memo: "",
      },
      statusVisible: false, //关联应用弹框默认不显示
      tableDataNmae: [], // 点击名称的列表
      // 新增校验
      rules: {
        name: [
          {
            required: true,
            message: "请输入类别名称",
            trigger: "blur",
          },

          { min: 1, max: 8, message: "最长输入 8 个字符", trigger: "blur" },
          { validator: validcodeName, trigger: "blur" },
        ],
      },
      // 编辑校验
      rulesEdit: {
        name: [
          {
            required: true,
            message: "请输入类别名称",
            trigger: "blur",
          },
          { min: 1, max: 8, message: "最长输入 8 个字符", trigger: "blur" },
          { validator: validcodeName, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.tableList();
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 保存
    sunline(data) {
      if (data == 1) {
        this.tableList();
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
      }
      console.log(data);
      this.flag = data;
    },
    // 列表
    async tableList() {
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: this.listData.pageSize,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule,
        gwName: "",
        ids: [],
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/gateway/getDataInfo",
        param,
        true
      );
      this.tableData = res.data.content; //列表数据
      this.listData.total = res.data.total; //列表总数
    },
    // 查询 onSbumit
    async onSbumit() {
      if (this.input.length > 0) {
        this.listData.currentPage = 1;
      }
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: this.listData.pageSize,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule,
        gwName: this.input,
        ids: [],
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/gateway/getDataInfo",
        param,
        true
      );
      this.tableData = res.data.content; //列表数据
      this.listData.total = res.data.total; //列表总数
    },
    // 新增
    add() {
      this.flag = 2;
      this.addArray = null;
      this.addArrayId = "";
      this.input = "";
    },
    edit(row) {
      this.addArray = row;
      this.addArrayId = row.id + "";
      this.flag = 2;
      this.input = "";
    },
    deletes(val) {
      this.$confirm(
        this.$t("AccessConfiguration.GatewayConfig.Deletgateway"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(() => {
          this.del(val);
        })
        .catch(() => {
          return false;
        });
    },
    // 删除
    async del(row) {
      var param = {
        ids: [String(row.id)],
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/gateway/delete",
        param,
        true
      );
      if (res.code == 500) {
        this.$message({
          message: res.message,
          type: "error",
          offset: 100,
        });
      } else {
        this.$message({
          message: this.$t("public.SuccessfullyDeleted"),
          type: "success",
          offset: 100,
        });
        this.$refs.multipleTable.clearSelection();
        this.listData.currentPage = 1;
        this.tableList();
      }
    },

    //多条删除
    delMuch(obj) {
      this.$confirm(
        this.$t("AccessConfiguration.GatewayConfig.Deletgateway"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          var ids = [];
          obj.forEach((item) => {
            ids.push(item.id);
          });
          var param = {
            ids: ids,
          };
          console.log(param, "wewdwefwed");
          const res = await this.$axios.post(
            "/httpServe/sdp/gateway/delete",
            param,
            true
          );
          if (res.code == 500) {
            this.$message({
              message: res.message,
              type: "error",
              offset: 100,
            });
            this.$refs.multipleTable.clearSelection();
          } else {
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
            this.$refs.multipleTable.clearSelection();
            this.listData.currentPage = 1;
            this.tableList();
          }
        })
        .catch(() => {
          return false;
        });
    },
    //表格当删除发生变化
    handleSelectionChange(obj) {
      console.log(obj, "obj");

      if (obj.length >= 1) {
        this.delarr = obj;
        this.delType = "primary";
        this.delFlag = false;
      } else {
        this.delarr = obj;
        this.delType = "info";
        this.delFlag = true;
      }
    },
    //当用户手动勾选数据行的 Checkbox 时触发的事件
    checkChange(selection, row) {
      let arr1 = [];
      selection.forEach((item) => {
        arr1.push(item.id);
        //去重
        this.delarr = [...new Set(arr1)];
      });
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.listData.currentPage = page;
      this.listData.pageSize = limit;
      // 调用列表
      this.tableList();
    },
    // 排序
    sort(column) {
      if (column.order === "ascending") {
        this.listData.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.listData.orderRule = "DESC";
      }
      this.listData.orderColume = column.prop;
      this.tableList();
    },
    // 高度
    heightCustom() {
      this.height.height = this.num;
    },
    // 点击名称详情
    async nameKuang(row) {
      this.dataId = row.id;
      this.statusVisible = true;
      var param = {
        gwId: row.id,
        name: this.ipt,
      };
      const res = await this.$axios.post(
        "/httpServe/entAppPermission/selectByGateway",
        param,
        true
      );
      console.log(res, "名称详情");
      this.tableDataNmae = res.data;
      this.dataTolCount = res.data.length;
      var data = [];
    },

    async updateData() {
      var param = {
        gwId: this.dataId,
        name: this.ipt,
      };
      const res = await this.$axios.post(
        "/httpServe/entAppPermission/selectByGateway",
        param,
        true
      );
      this.tableDataNmae = res.data;
      this.dataTolCount = res.data.length;
    },
    // 弹框点击关闭事件
    handleClose() {
      this.statusVisible = false; //点击出现详情弹框
      this.visibaelFlag = false; //提示消息弹框关闭
      this.ipt = ""; //关联应用清空条件
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //确定的关闭
    determine() {
      this.visibaelFlag = false;
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
        this.disabled = false;
      }
      this.isCheckShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item__label {
  padding: 0 0 0 0 !important;
}

::v-deep .el-input--small .el-input__inner {
  height: 34px;
  line-height: 34px;
}
::v-deep .el-textarea__inner {
  font-family: "Microsoft YaHei";
}
::v-deep .el-textarea__inner::placeholder {
  font-family: "Microsoft YaHei";
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>

<template>
  <div class="bigbox" style="margin-top: 20px; margin-left: 60px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="145px"
      class="demo-ruleForm"
      style="margin-bottom: -17px"
    >
      <el-form-item
        :label="$t('AccessConfiguration.GatewayConfig.GatewayName') + '：'"
        prop="gwName"
      >
        <el-input
          @input="(e) => (ruleForm.gwName = validForbid(e))"
          v-model="ruleForm.gwName"
          maxlength="30"
          style="width: 250px"
          size="small"
          :placeholder="$t('AccessConfiguration.GatewayConfig.Placeholder')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('AccessConfiguration.GatewayConfig.GatewayAddress') + '：'"
        :required="true"
      >
        <div style="width: 76px; margin-right: 5px">
          <el-select
            v-model="ruleForm.gwType"
            :placeholder="$t('public.DomainName')"
            size="small"
            @change="changeName"
          >
            <el-option
              v-for="item in devNameOptions"
              :key="item.value"
              :label="
                $t(
                  `AccessConfiguration.GatewayConfig.DevNameOptions.${item.label}`
                )
              "
              :value="item.label"
            >
            </el-option>
          </el-select>
        </div>
        <el-form-item prop="gwAddress">
          <el-input
            v-model="ruleForm.gwAddress"
            maxlength="100"
            style="width: 168px; margin-right: 10px"
            size="small"
            :placeholder="`请输入${placeholder}`"
          ></el-input>
        </el-form-item>
        <!-- </div> -->
      </el-form-item>
      <el-form-item :label="$t('public.Port') + '：'" prop="gwPort">
        <el-input
          v-model="ruleForm.gwPort"
          maxlength="5"
          style="width: 250px"
          size="small"
          :placeholder="$t('AccessConfiguration.GatewayConfig.Placepost')"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('AccessConfiguration.GatewayConfig.DayMaxvalue') + '：'"
        prop="maxRequest"
      >
        <el-input
          v-model="ruleForm.maxRequest"
          placeholder="1~99999"
          maxlength="5"
          @input="handleChange1"
          size="small"
          style="width: 110px; height: 20px"
        ></el-input
        >&nbsp;GB
      </el-form-item>
      <el-form-item
        :label="$t('AccessConfiguration.GatewayConfig.Maxconnect') + '：'"
        prop="maxConcurrent"
      >
        <el-input
          v-model="ruleForm.maxConcurrent"
          placeholder="1~99999"
          maxlength="5"
          @input="handleChange"
          size="small"
          style="width: 110px; height: 20px"
        ></el-input
        >&nbsp;{{ $t("AccessConfiguration.GatewayConfig.individual") }}
      </el-form-item>
      <el-form-item
        :label="$t('AccessConfiguration.GatewayConfig.Protected') + '：'"
        required
      >
        <div
          style="
            width: 750px;
            margin: 8px 10px 0 0px;
            display: flex;
            height: 332px;
          "
        >
          <el-table
            ref="multipleTable"
            :data="dictData"
            stripe
            size="mini"
            tooltip-effect="dark"
            class="tableClass"
            :cell-style="iCellStyle"
            :header-row-style="iHeaderRowStyle"
            :header-cell-style="{
              background: '#f7f8fa',
              color: '#606266',
              padding: '0px',
            }"
            style="width: 100%; overflow-y: auto"
          >
            <el-table-column
              header-align="left"
              align="left"
              prop="resName"
              :label="$t('AccessConfiguration.GatewayConfig.ResourceName')"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <!-- <el-table-column header-align="left" align="left" prop="resAddress" label="资源地址" width="120" show-overflow-tooltip>
            </el-table-column> -->
            <el-table-column
              style="color: #6f7982"
              prop="protocolType"
              :label="$t('AccessConfiguration.GatewayConfig.ProtocolType')"
              width="120"
              :show-overflow-tooltip="true"
            >
              <template v-slot="{ row }">
                <template v-if="row.protocolType == 1">TCP</template>
                <template v-if="row.protocolType == 2">UDP</template>
              </template>
            </el-table-column>
            <el-table-column
              style="color: #6f7982"
              prop="virtualIp"
              :label="$t('AccessConfiguration.GatewayConfig.VirtualAddress')"
              width="120"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              style="color: #6f7982"
              prop="resIp"
              :label="$t('AccessConfiguration.GatewayConfig.RealAddress')"
              width="120"
              :show-overflow-tooltip="true"
            >
              <template v-slot="{ row }">
                <template v-if="row.resType == 2 || row.resType == 3">{{
                  row.resAddress
                }}</template>
                <template v-else>{{ row.resIp }}</template>
              </template>
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop="resPort"
              :label="$t('public.Port')"
              width="70"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop=""
              :label="$t('public.Controls')"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                <img
                  @click="delapp(row.id)"
                  src="@/images/icon_del.png"
                  alt=""
                  style="
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    cursor: pointer;
                  "
                  :title="$t('public.Delete')"
                />
              </template>
            </el-table-column>
          </el-table>

          <el-button
            @click="addData"
            type="primary"
            size="mini"
            style="
              margin-left: 10px;
              border-radius: 15px;
              height: 30px !important;
            "
            icon="el-icon-plus"
            >{{
              $t("AccessConfiguration.GatewayConfig.AddResources")
            }}</el-button
          >
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          size="small"
          @click="submitForm('ruleForm')"
          type="primary"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 保存弹框 -->
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <!--  -->
    <Eldialog
      :num="'470'"
      :width="'900'"
      :title="$t('AccessConfiguration.GatewayConfig.Resourcelist')"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
      :btnTitle="$t('public.Join')"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="joinType"
    >
      <div>
        <el-input
          v-model="ipt"
          @input="(e) => (ipt = validForbid(e))"
          @keyup.enter.native="onSbumit"
          size="small"
          style="width: 180px; margin-bottom: 10px"
          :placeholder="$t('AccessConfiguration.GatewayConfig.Placeholders')"
        ></el-input>
        <!-- v-model="ipt" -->
        <el-button
          @click="onSbumit"
          type="primary"
          size="small"
          style="margin-left: 3px"
          >{{ $t("public.Inquire") }}</el-button
        >
        <el-table
          size="mini"
          stripe
          ref="multipleTables"
          :data="tableDataNmae"
          :row-key="getRowKeys"
          class="sort_table"
          tooltip-effect="dark"
          :default-sort="{ prop: 'updateTime', order: 'descending' }"
          :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            align="center"
            :reserve-selection="true"
            type="selection"
            width="60"
          >
          </el-table-column>
          <el-table-column
            prop="resName"
            :label="$t('AccessConfiguration.GatewayConfig.ResourceName')"
            width="120"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- <el-table-column prop="resAddress" label="资源地址" width="200" :show-overflow-tooltip="true">
          </el-table-column> -->
          <el-table-column
            style="color: #6f7982"
            prop="protocolType"
            :label="$t('AccessConfiguration.GatewayConfig.ProtocolType')"
            width="140"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.protocolType == 1">TCP</template>
              <template v-if="row.protocolType == 2">UDP</template>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="virtualIp"
            :label="$t('AccessConfiguration.GatewayConfig.VirtualAddress')"
            width="140"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="resIp"
            :label="$t('AccessConfiguration.GatewayConfig.RealAddress')"
            width="140"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.resType == 2 || row.resType == 3">{{
                row.resAddress
              }}</template>
              <template v-else>{{ row.resIp }}</template>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="resPort"
            :label="$t('public.Port')"
            width="70"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="updateTime"
            :label="$t('public.ModificationTime')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.time == ''"></span
              ><span class="detaildesc" v-else>
                {{ scope.row.updateTime | dateformat }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <pagination
            :page="listData.currentPage"
            :limit="listData.pageSize"
            :total="listData.total"
            :pageSizes="[10, 20, 30, 40]"
            v-on:handleChildGetList="handleParentGetList"
          />
        </div>
      </div>
    </Eldialog>
  </div>
</template>
<script>
// 弹框
import Eldialog from "@/components/elDialog";
import dialogInfo from "@/components/promptMessage/index";
import pagination from "@/components/pagination/page.vue"; //分页页
var iplist =
  /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))|\*)((\/([012]\d|3[012]|\d))?)(,((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))|\*)((\/([012]\d|3[012]|\d))?))*$/;
var urlist =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
var ipduan =
  /^(?=(\b|\D))(((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))\.){3}((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))(?=(\b|\D))-(?=(\b|\D))(((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))\.){3}((\d{1,2})|(1\d{1,2})|(2[0-4]\d)|(25[0-5]))(?=(\b|\D))$/;
var ipv6 =
  /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
var nameRegex =
  /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
export default {
  components: { Eldialog, dialogInfo, pagination },
  props: {
    addArray: {
      type: Object,
      default: () => {},
    },
    addArrayId: {
      type: String,
      default: "",
    },
  },
  data() {
    // 验证正整数
    const blurText = (rule, value, callback) => {
      // const reg = /^-d.?d*$/
      // const boolean = reg.test(value)
      const boolean = new RegExp("^[1-9][0-9]*$").test(value);
      if (!boolean) {
        callback(new Error("仅允许输入正整数"));
      } else if (value > 65535 || value < 1) {
        callback(new Error("数值范围：1-65535"));
      } else {
        callback();
      }
    };
    // 下拉选的正则校验
    var effectTimeChk = (rule, value, callback) => {
      if (this.ruleForm.gwType == 1 || this.ruleForm.gwType == "域名") {
        // /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/    原来的
        // /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
        // /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*$/;

        let nameRegex =
          /^(http(s)?:\/\/)?(www\.)?([0-9a-z-]{1,}.)?[0-9a-z-]{2,}.([0-9a-z-]{2,}.)?[a-z]{2,}$/;
        let Subdomain = /^[a-zA-Z\d-]+(\.[a-zA-Z\d-]+){3}$/;
        console.log(nameRegex.test(value), "nameRegex.test(value)");
        console.log(1111, "域名");
        if (!nameRegex.test(value) || !Subdomain.test(value)) {
          console.log(33333, "域名等于空");
          callback(new Error("请输入正确的域名，如：www.baidu.com"));
        } else {
          callback();
        }
      } else if (this.ruleForm.gwType == 2 || this.ruleForm.gwType == "IP") {
        console.log(5555, " ip");
        let reg =
          /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
        let regIpv6 =
          /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
        let valdata = value.split(","); //IP
        let isCorrect = true; //IP

        if (valdata.length) {
          for (let i = 0; i < valdata.length; i++) {
            if (
              reg.test(valdata[i]) == false &&
              regIpv6.test(valdata[i]) == false
            ) {
              isCorrect = false;
            }
          }
        }
        if (!isCorrect) {
          console.log(6666, "ip正则验证");
          callback(new Error("请输入正确的IP地址,如：192.168.0.1"));
        } else {
          callback();
        }
      }
    };
    // 验证域名
    const validateName = (rule, value, callback) => {
      // debugger
      console.log(value, "value验证域名或IP");
      // IP正则
      let reg =
        /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
      let regIpv6 =
        /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
      let valdata = value.split(",");
      let isCorrect = true;
      if (valdata.length) {
        for (let i = 0; i < valdata.length; i++) {
          if (
            reg.test(valdata[i]) == false &&
            regIpv6.test(valdata[i]) == false
          ) {
            isCorrect = false;
          }
        }
      }

      var IP =
        "^((25[0-5]|2[0-4]\\d|[1]{1}\\d{1}\\d{1}|[1-9]{1}\\d{1}|\\d{1})($|(?!\\.$)\\.)){4}$";
      var IPtest = new RegExp(IP);

      // 域名正则
      const nameRegex =
        /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;

      if (!IPtest.test(value)) {
        // callback(new Error('请输入正确的ip地址'));
        this.ipFlag = 0;
        console.log(nameRegex.test(value), "nameRegex.test(value)");
        if (!nameRegex.test(value)) {
          // callback(new Error("请输入正确的域名，如：www.baidu.com"));
          // debugger;
          this.ipFlag = 0;
          callback(new Error("请输入正确的域名或IP地址"));
        } else {
          // this.ruleForm.gwType=1
          this.ipFlag = 1;
          if (!nameRegex.test(value)) {
            callback(new Error("请输入正确的域名，如：www.baidu.com"));
          } else {
            callback();
          }
        }
      } else {
        if (!isCorrect) {
          callback(new Error("请输入正确的IP地址,如：192.168.0.1"));
        } else {
          this.ipFlag = 0;
          callback();
        }
      }
    };
    // 验证ip
    const validateIps = (rule, value, callback) => {
      let reg =
        /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
      let regIpv6 =
        /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
      let valdata = value.split(",");
      let isCorrect = true;
      if (valdata.length) {
        for (let i = 0; i < valdata.length; i++) {
          if (
            reg.test(valdata[i]) == false &&
            regIpv6.test(valdata[i]) == false
          ) {
            isCorrect = false;
          }
        }
      }
      if (!isCorrect) {
        callback(new Error("请输入正确的IP地址,如：192.168.0.1"));
      } else {
        callback();
      }
    };
    return {
      selectionId: [], //id
      ids: [], //IDS=====
      statusVisible: false, //添加资源弹框
      titleInfo: "保存成功",
      visibaelFlag: false,
      queryObj: {
        manage: 1,
        // selectcont: "",
        belong: "",
      },
      options: [],
      osId: "20",
      // 列表参数
      listData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      },
      dictData: [], //资源  如果是[{}]就会多一个空数据
      geofences: [],
      tableDataNmae: [], //资源列表
      multipleSelection: [],
      dataTolCount: 0, //资源总数
      selectionId: [], // 资源选择id
      ipt: "", //弹框搜索
      ruleForm: {
        gwName: "",
        gwType: "域名",
        gwAddress: "",
        gwPort: "",
        resourceIds: "",
        maxConcurrent: "",
        maxRequest: "",
      },
      rules: {
        gwName: [
          { required: true, message: "请输入网关名称", trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: "最多支持输入30个字符",
            trigger: "blur",
          },
        ],
        gwAddress: [
          // {  message: '请输入域名地址', trigger: 'blur' },
          {
            required: true,
            min: 1,
            max: 100,
            validator: effectTimeChk,
            trigger: "blur",
          },
        ],
        gwPort: [
          { required: true, message: "请输入端口", trigger: "blur" },
          { min: 1, max: 65535, validator: blurText, trigger: "blur" },
        ],
      },
      path: "",
      dataFrom: {}, //应用详情组件
      dialogVisible: false, //详情弹框默认--关闭
      id: "",
      devNameOptions: [
        { value: 1, label: "域名" },
        { value: 2, label: "IP" },
      ],
      placeholder: "域名地址",
      seleShift: 1, // 选中下拉框转译
      ipFlag: 1,
    };
  },
  watch: {
    pageContent: {
      handler: function (newVal, oldVal) {
        this.pageContent = newVal;
        this.allMethods();
      },
      deep: true, // immediate: true,
    },
  },
  created() {
    this.Resources();
    this.IDS = this.addArrayId;
    console.log(this.IDS, "------id-----");
    console.log(this.addArrayId);
    // this.tableDataNmae  资源列表
    if (this.addArray && this.addArray.id) {
      this.ruleForm.gwName = this.addArray.gwName;
      this.ruleForm.gwType = this.addArray.gwType;
      this.seleShift = this.ruleForm.gwType;
      this.devNameOptions.forEach((k) => {
        console.log(k, "000000");
        if (this.addArray.gwType == k.value) {
          this.ruleForm.gwType = k.label;
        }
      });
      this.ruleForm.gwAddress = this.addArray.gwAddress;
      this.ruleForm.gwPort = this.addArray.gwPort;
      this.ruleForm.maxConcurrent = this.addArray.maxConcurrent;
      this.ruleForm.maxRequest = this.addArray.maxRequest;
      if (this.ruleForm.maxConcurrent == 0) {
        this.ruleForm.maxConcurrent = "";
      }
      if (this.ruleForm.maxRequest == 0) {
        this.ruleForm.maxRequest = "";
      }
      this.selectionId = this.addArray.resourceIds.split(",");
      console.log(this.selectionId, this.selectionId.length, "12121212");
    }
    setTimeout(() => {
      this.deatlise();
    }, 500);
  },
  mounted() {
    this.SSS();
  },
  methods: {
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    handleChange() {
      // 通过正则表达式判断输入是否符合要求
      if (/^(0|[1-9][0-9]*)$/.test(this.ruleForm.maxConcurrent)) {
        // 转换为整数并限制范围为1-100
        let num = parseInt(this.ruleForm.maxConcurrent);
        if (num < 1) {
          this.$message({
            message: this.$t("AccessConfiguration.GatewayConfig.Onlysupports"),
            type: "error",
            offset: 100,
            duration: 2000,
          });
          this.ruleForm.maxConcurrent = "";
          return false;
        } else if (num > 99999) {
          this.$message({
            message: this.$t("AccessConfiguration.GatewayConfig.Onlysupports"),
            type: "error",
            offset: 100,
            duration: 2000,
          });
          this.ruleForm.maxConcurrent = "";
          return false;
        }
        this.ruleForm.maxConcurrent = num.toString();
      } else {
        // 不符合要求则清空输入框
        this.ruleForm.maxConcurrent = "";
      }
    },
    handleChange1() {
      if (/^(0|[1-9][0-9]*)$/.test(this.ruleForm.maxRequest)) {
        // 转换为整数并限制范围为1-100
        let num = parseInt(this.ruleForm.maxRequest);
        if (num < 1) {
          this.$message({
            message: this.$t("AccessConfiguration.GatewayConfig.Onlysupports"),
            type: "error",
            offset: 100,
            duration: 2000,
          });
          this.ruleForm.maxRequest = "";
          return false;
        } else if (num > 99999) {
          this.$message({
            message: this.$t("AccessConfiguration.GatewayConfig.Onlysupports"),
            type: "error",
            offset: 100,
            duration: 2000,
          });
          this.ruleForm.maxRequest = "";
          return false;
        }
        this.ruleForm.maxRequest = num.toString();
      } else {
        // 不符合要求则清空输入框
        this.ruleForm.maxRequest = "";
      }
    },
    // 下拉框选择转义
    changeName(item, formName) {
      console.log(item, "item");
      this.placeholder =
        item + this.$t("AccessConfiguration.GatewayConfig.Address");
      if (item == "IP") {
        this.rules.gwAddress[0].message = this.$t(
          "AccessConfiguration.GatewayConfig.PleaseIP"
        );
      }
      if (item == this.$t("AccessConfiguration.GatewayConfig.Domainname")) {
        this.rules.gwAddress[0].message = this.$t(
          "AccessConfiguration.GatewayConfig.PleaseDomain"
        );
      }
      this.devNameOptions.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
        }
      });
      //先var一个变量存一下 清完之后在单独赋值给
      var name = this.ruleForm.gwName;
      this.$refs["ruleForm"].resetFields();
      this.ruleForm.gwName = name;
    },
    // 弹框全选
    getRowKeys(row) {
      return row.id;
    },
    // 遍历编辑弹框
    deatlise() {
      this.tableDataNmae.forEach((item) => {
        this.selectionId.forEach((newItem) => {
          if (item.id == newItem) {
            this.dictData.push({
              id: item.id,
              resName: item.resName,
              resAddress: item.resAddress,
              resPort: item.resPort,
              protocolType: item.protocolType,
              virtualIp: item.virtualIp,
              resIp: item.resIp,
              resType: item.resType,
            });
          }
        });
      });
    },
    // 弹框关闭
    handleClose() {
      this.ipt = "";
      this.listData.currentPage = 1;
      this.statusVisible = false;
      this.visibaelFlag = false;
      this.dialogVisible = false;
      this.$refs.multipleTables.clearSelection();
    },
    heightCustom() {
      this.height.height = this.num;
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false;
    },
    // 添加资源
    addData() {
      this.listData.currentPage = 1;
      this.statusVisible = true;
      this.Resources();
    },
    // 添加资源列表
    async Resources() {
      if (this.ipt.length > 0 && this.listData.currentPage > 1) {
        this.listData.currentPage = 1;
      }
      var param = {
        currentPage: this.listData.currentPage, //1
        pageSize: this.listData.pageSize, //10
        searchType: "resName",
        searchContent: this.ipt,
        orderRule: "DESC",
        orderColume: "update_time",
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/resource/getDataInfo",
        param,
        true
      );
      this.tableDataNmae = res.data.content; //列表数据
      console.log(this.tableDataNmae, "网关资源列表数据");
      this.listData.total = res.data.total; //列表总数
      this.$nextTick(() => {
        this.tableDataNmae.forEach((item, index) => {
          this.dictData.forEach((i, j) => {
            if (item.id == i.id) {
              // this.$refs.multipleTables.toggleRowSelection(item, true);
            }
          });
        });
      });
      this.$emit("xingwei", this.permission);
    },
    async SSS() {
      console.log(2342342342);
      if (this.ipt.length > 0) {
        this.listData.currentPage = 1;
      }
      var param = {
        currentPage: this.listData.currentPage, //1
        pageSize: 999, //10
        searchType: "resName",
        ids: this.selectionId,
        orderRule: "DESC",
        orderColume: "update_time",
      };
      const res = await this.$axios.post(
        "/httpServe/sdp/resource/getDataInfo",
        param,
        true
      );
      this.tableDataNmae = res.data.content; //列表数据
      this.listData.total = res.data.total; //列表总数
      this.$nextTick(() => {
        this.tableDataNmae.forEach((item, index) => {
          this.dictData.forEach((i, j) => {
            if (item.id == i.id) {
              // this.$refs.multipleTables.toggleRowSelection(item, true);
            }
          });
        });
      });
      this.$emit("xingwei", this.permission);
    },
    // 添加资源   选中
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [...val];
    },
    // 添加资源   添加
    joinType() {
      // this.dictData = [];
      this.multipleSelection.forEach((item, index) => {
        this.dictData.push({
          id: item.id,
          resName: item.resName,
          resAddress: item.resAddress,
          resPort: item.resPort,
          protocolType: item.protocolType,
          virtualIp: item.virtualIp,
          resIp: item.resIp,
          resType: item.resType,
        });
      });
      var result = [];
      var obj = {};
      for (var i = 0; i < this.dictData.length; i++) {
        if (!obj[this.dictData[i].resName]) {
          result.push(this.dictData[i]);
          obj[this.dictData[i].resName] = true;
        }
      }
      this.dictData = result;
      this.dictData.forEach((item, index) => {
        this.geofences.push(String(item.id));
        this.selectionId = this.geofences;
      });
      this.statusVisible = false;
      this.$emit("xingwei", this.permission);
      this.$refs.multipleTables.clearSelection();
    },
    delapp(val) {
      var del_result = this.dictData.filter((item) => item.id != val);
      console.log(del_result, "-del_result");
      this.dictData = del_result;
      var arr = [];
      this.dictData.forEach((item, index) => {
        arr.push(item.id);
      });
      this.selectionId = arr;
      console.log(this.selectionId, "v");
      this.$refs.multipleTables.clearSelection();
    },
    // 资源列表查询
    onSbumit() {
      this.Resources();
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.listData.currentPage = page;
      this.listData.pageSize = limit;
      // 调用列表
      this.Resources();
    },
    // 校验框重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    heightCustom() {
      this.height.height = this.num;
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false;
    },
    async saveSubmit() {
      if (this.IDS) {
        if (
          this.ruleForm.gwPort < parseInt(1) ||
          this.ruleForm.gwPort > parseInt(65535)
        ) {
          this.$message({
            message: this.$t("AccessConfiguration.GatewayConfig.PostNumber"),
            type: "error",
            offset: 100,
            duration: 2000,
          });
          return false;
        }

        let params = {
          ids: [this.IDS + ""],
          gwName: this.ruleForm.gwName,
          gwType: this.seleShift,
          gwAddress: this.ruleForm.gwAddress,
          gwPort: this.ruleForm.gwPort,
          resourceIds: [...new Set(this.selectionId)],
          maxConcurrent: this.ruleForm.maxConcurrent,
          maxRequest: this.ruleForm.maxRequest,
        };
        console.log(params);
        const res = await this.$axios.post(
          "/httpServe/sdp/gateway/update",
          params,
          true
        );
        console.log(res);

        if (res.code == 500) {
          this.$message({
            message: res.message,
            type: "error",
            offset: 100,
          });
        }
        if (this.tableDataNmae.lentgth == 0 || res.code == 101) {
          this.$message({
            message: this.$t("AccessConfiguration.GatewayConfig.PlaceService"),
            type: "error",
            offset: 100,
            duration: 2000,
          });
        }
        if (res.code == 200) {
          var flag = 1;
          this.$emit("sunline", flag);
        }
      } else {
        if (
          this.ruleForm.gwPort < parseInt(1) ||
          this.ruleForm.gwPort > parseInt(65535)
        ) {
          this.$message({
            message: this.$t("AccessConfiguration.GatewayConfig.PostNumber"),
            type: "error",
            offset: 100,
            duration: 2000,
          });
          return false;
        }
        let params = {
          gwName: this.ruleForm.gwName,
          gwType: this.seleShift,
          gwAddress: this.ruleForm.gwAddress,
          gwPort: this.ruleForm.gwPort,
          resourceIds: [...new Set(this.selectionId)],
          maxConcurrent: this.ruleForm.maxConcurrent,
          maxRequest: this.ruleForm.maxRequest,
        };
        console.log(params);
        const res = await this.$axios.post(
          "/httpServe/sdp/gateway/save",
          params,
          true
        );
        console.log(res);

        if (res.code == 200) {
          var flag = 1;
          this.$emit("sunline", flag);
        }
        if (res.code == 500) {
          this.$message({
            message: res.message,
            type: "error",
            offset: 100,
          });
        }
        if (res.code == 101) {
          this.$message({
            message: this.$t("AccessConfiguration.GatewayConfig.PlaceService"),
            type: "error",
            offset: 100,
            duration: 2000,
          });
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveSubmit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  position: relative;
  font-size: 14px;
  display: flex;
}
::v-deep #detailBox .el-dialog__body {
  overflow-x: hidden !important;
}
::v-deep .el-button--mini {
  // height: 30px !important;
  font-size: 12px;
  border-radius: 3px;
}
::v-deep .el-form-item__error {
  width: 300px !important;
}
</style>

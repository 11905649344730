<template>
  <div class="app-container calendar-list-container">
    <div
      class="pagination-container"
      style="
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 12px;
        color: #000;
      "
    >
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :page-size="limit"
        layout="slot, sizes"
        :total="total"
        style="float: left; font-size: 12px; color: #000"
      >
        <!-- <span>{{
          "当前第" + page + "页" + ",   " + "共" + this.lastPage + "页"
        }}</span> -->
        <span
          style="
            font-weight: 400;
            color: #000;
            float: left;
            font-size: 12px;
            line-height: 24px;
          "
          >{{$t('public.total')}}{{ total }}{{num<=5?$t('public.recordss'):$t('public.records')}}</span
        >
      </el-pagination>
      <div v-if="pagintionBtn">
        <el-pagination
          small
          background
          @size-change="handleSizeChange"
          :firstPage="firstPage"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="limit"
          layout="slot"
          :total="total"
          style="float: left; font-size: 12px; color: #000"
        >
          <!-- 首页  -->
          <el-button
            :disabled="page == 1"
            style="background: #f4f4f5"
            v-show="showFirstPage"
            class="el-icon-d-arrow-left"
            @click="toFirstPage"
          ></el-button>
        </el-pagination>
        <el-pagination
          small
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="pageSizes"
          :page-size="limit"
          :pager-count="num"
          :lastPage="lastPage"
          layout="prev, pager, next, slot, jumper"
          :total="total"
          style="
            float: left;
            margin-left: -10px;
            font-size: 12px;
            color: #000;
            line-height: 24px;
          "
        >
          <!-- 尾页 -->
          <el-button
            :disabled="page==Math.ceil(total / limit)"
            style="background: #f4f4f5"
            v-show="showLastPage"
            class="el-icon-d-arrow-right"
            @click="toLastPage"
          ></el-button>
          <!-- 共几页 -->
          <span style="font-size: 12px; color: #000">{{$t('public.total')}}{{
            Math.ceil(total / limit)
          }}{{$t('public.page')}}</span>
        </el-pagination>
        <el-button
          v-show="showLastPage"
          type="primary"
          size="mini"
          style="
            padding: 0 5px;
            line-height: 24px;
            height: 24px;
            width: 38px;
            margin-top: 1px;
          "
          @click="handleGo"
          >{{$t('public.skip')}}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    // 父页面传值
    page: {
      type: Number,
    },
    limit: {
      type: Number,
    },
    total: {
      type: Number,
    },
    num: {
      type: Number,
      default:7
    },
    pageSizes: {
      type: Array,
    },
    showFirstPage: {
      type: Boolean,
      default:true
    },
    showLastPage: {
      type: Boolean,
      default:true
    }
  },
  data() {
    return {
      firstPage: 1,
      lastPage: Math.ceil(this.total / this.limit),
      pagintionBtn: true,
    };
  },
  watch: {
    total(newVal, oldVal) {
      this.total = newVal;
      if (this.total * 1 > 10) {
        this.pagintionBtn = true;
      } else {
        this.pagintionBtn = false;
      }
    },
    limit(newVal, oldVal) {
      this.limit = newVal;
      // console.log(this.limit, "this.limit-------newVal");
    },
    // 开启深度监听
    deep: true,
  },
  created() {},
  mounted() {
    if (this.total * 1 > 10) {
      this.pagintionBtn = true;
    } else {
      this.pagintionBtn = false;
    }
  },
  // computed: {
  //   lastPage: function() {
  //     return Math.ceil(this.total / this.limit);
  //   }
  // },
  methods: {
    // 首页
    toFirstPage() {
      this.firstPage = 1;
      // this.showFirstPage = false;
      // this.showLastPage = true;
      this.$emit("handleChildGetList", this.firstPage, this.limit);
    },
    // 尾页
    toLastPage() {
      this.lastPage = Math.ceil(this.total / this.limit);
      // this.showFirstPage = true;
      // this.showLastPage = false;
      this.$emit("handleChildGetList", this.lastPage, this.limit);
    },
    // 响应数据列表选择页面显示记录数事件
    handleSizeChange(val) {
      // console.log(val, "响应数据列表选择页面显示记录数事件");
      if (this.total < val) {
        // this.showFirstPage = false;
        // this.showLastPage = false;
      }
      this.$emit("handleChildGetList", 1, val);
    },
    // 响应数据列表选择页码事件
    handleCurrentChange(val) {
      if (val == 1) {
        // this.showFirstPage = false;
        // this.showLastPage = true;
      } else if (1 < val < this.lastPage) {
        this.showFirstPage = true;
        // this.showLastPage = false;
      } else if (val == this.lastPage) {
        // this.showLastPage = false;
        this.showFirstPage = true;
      }
      this.$emit("handleChildGetList", val, this.limit);
    },
    // 响应go事件
    handleGo() {
      this.$emit("handleChildGetList", this.page, this.limit);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-pagination--small .el-pagination__editor, .el-pagination--small .el-pagination__editor.el-input .el-input__inner {
  width: 30px;
}
</style>
